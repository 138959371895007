import { useLayoutEffect } from 'react'
import { AxiosInstance } from 'axios'
import { useAuth } from '@matillion/hub-client'

const useAuthInterceptor = (api: AxiosInstance) => {
  const { getToken } = useAuth()

  useLayoutEffect(() => {
    const id = api.interceptors.request.use(async (request) => {
      const token = await getToken()

      request.headers.set('Authorization', `Bearer ${token}`)

      return request
    })

    return () => {
      api.interceptors.request.eject(id)
    }
  }, [api, getToken])
}

export default useAuthInterceptor
