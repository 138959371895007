import {
  HeaderMenuItemGroup,
  HeaderMenuItem,
  UserMenu,
  Typography
} from '@matillion/component-library'
import { FunctionComponent, useCallback } from 'react'

import FlagProvider from '../../FlagProvider'
import getConfig from '../../config'
import useEnvironment from '../../effects/useEnvironment'
import useFlags from '../../effects/useFlags'
import useAuth from '../../effects/useAuth'
import useUser from '../../effects/useUser'

// TODO: switch to import classes syntax when migrating to microverse
import './HubUserMenu.scss'

import MyDetailsLink from './components/MyDetailsLink'
import SwitchOrganisationLink from './components/SwitchOrganisationLink'

const HubUserMenu: FunctionComponent = () => {
  const { environment } = useEnvironment()
  const { launchDarkly } = getConfig(environment)

  return (
    <FlagProvider
      launchDarklyClientId={launchDarkly.defaultClientId}
      flags={launchDarkly.defaultFlags}
    >
      <FlaggedMenu />
    </FlagProvider>
  )
}

const FlaggedMenu: FunctionComponent = () => {
  const { profile, organisation } = useUser()
  const { logout } = useAuth()
  const { rolloutNavMenuPublicId } = useFlags()

  const onLogout = useCallback(() => {
    logout({ returnTo: window.location.origin })
  }, [])

  return (
    <UserMenu profile={profile} data-testid="hub-user-menu">
      <HeaderMenuItemGroup small>
        <HeaderMenuItem as={MyDetailsLink}>My details</HeaderMenuItem>
        <HeaderMenuItem as={SwitchOrganisationLink}>
          Switch account
        </HeaderMenuItem>
      </HeaderMenuItemGroup>

      <HeaderMenuItemGroup small>
        <HeaderMenuItem as="button" onClick={onLogout}>
          Logout
        </HeaderMenuItem>
        {organisation.publicId && rolloutNavMenuPublicId && (
          <div className="HeaderMenuAccountId">
            <Typography format="mc">
              Account ID: {organisation.publicId}
            </Typography>
          </div>
        )}
      </HeaderMenuItemGroup>
    </UserMenu>
  )
}

export default HubUserMenu
