import { createContext } from 'react'

import { Invitation, Member } from './types'

interface MembersContextBag {
  isLoading: boolean
  members: Member[]
  invitations: Invitation[]
  error?: unknown
}

export const MembersContext = createContext<MembersContextBag>({
  isLoading: false,
  members: [],
  invitations: [],
  error: undefined
})
