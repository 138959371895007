import { VoidFunctionComponent } from 'react'
import { AppMenu, AppMenuItem, Icon } from '@matillion/component-library'

import MenuNavLink from 'components/MenuNavLink'
import { useFlags, useUser } from '@matillion/hub-client'

const AppMenuComponent: VoidFunctionComponent = () => {
  const { enableManageMenu } = useFlags()
  const { roles } = useUser()
  const isAdmin = roles.has('hub:admin')

  return (
    <AppMenu>
      <MenuNavLink
        key="home"
        as={AppMenuItem}
        to="/"
        exact
        name="Home"
        icon={<Icon.World />}
      />
      <MenuNavLink
        key="users"
        as={AppMenuItem}
        to="/members"
        name="Users"
        icon={<Icon.Group />}
      />
      <>
        {enableManageMenu && isAdmin && (
          <MenuNavLink
            key="manage"
            as={AppMenuItem}
            to="/manage"
            name="Manage"
            icon={<Icon.Cog />}
          />
        )}
      </>
    </AppMenu>
  )
}

export default AppMenuComponent
