import { Button, Icon, Modal, Typography } from '@matillion/component-library'
import classes from './TrialExpiredModal.module.scss'
import { useLDClient, useServiceUrl } from '@matillion/hub-client'

interface TrialExpiredModalProps {
  onClose: () => void
}

const TrialExpiredModal = ({ onClose }: TrialExpiredModalProps) => {
  const { track: sendLDMetric, flush: flushLDMetrics } = useLDClient()
  const extensionHref = `${useServiceUrl('billing-frontend', true)}/contact`
  const subscribeHref = `${useServiceUrl(
    'billing-frontend',
    true
  )}/subscription/upgrade`

  return (
    <Modal
      ariaLabelledBy={'Trial expired modal'}
      data-testid="trial-expired-modal"
      data-tracker-id="trial-expired-modal"
      className={classes.TrialExpiredModal}
      size="default"
      onCancel={onClose}
    >
      <Icon.InfoNoBg className={classes.TrialExpiredModal__Icon} />
      <Typography
        as="h2"
        format="tl"
        weight="bold"
        className={classes.TrialExpiredModal__Title}
      >
        Your trial has ended
      </Typography>
      <Typography className={classes.TrialExpiredModal__Text}>
        Your free trial has ended and you no longer have access to Matillion
        Designer.
        <br />
        Click below to subscribe or to request an extension.
      </Typography>
      <div className={classes.TrialExpiredModal__ButtonGroup}>
        <a
          href={extensionHref}
          onClick={(event) => {
            event.preventDefault()
            sendLDMetric('hub-trial-expired-modal-request-extension-cta-click')
            flushLDMetrics()
            window.location.href = extensionHref
          }}
          data-testid={'trial-expired-modal-extension-cta'}
          data-tracker-id={'trial-expired-modal-extension-cta'}
        >
          <Button
            alt="secondary"
            size="lg"
            className={classes.TrialExpiredModal__ButtonGroup__Button}
            text="Request trial extension"
          />
        </a>
        <a
          href={subscribeHref}
          onClick={(event) => {
            event.preventDefault()
            sendLDMetric('hub-trial-expired-modal-subscribe-cta-click')
            flushLDMetrics()
            window.location.href = subscribeHref
          }}
          data-testid={'trial-expired-modal-subscribe-cta'}
          data-tracker-id={'trial-expired-modal-subscribe-cta'}
        >
          <Button
            alt="primary"
            size="lg"
            className={classes.TrialExpiredModal__ButtonGroup__Button}
            text="Subscribe"
          />
        </a>
      </div>
    </Modal>
  )
}

export default TrialExpiredModal
