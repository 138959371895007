const ONE_SECOND_IN_MILLISECONDS = 1000
const ONE_MINUTE_IN_MILLISECONDS = 60 * ONE_SECOND_IN_MILLISECONDS
const ONE_HOUR_IN_MILLISECONDS = 60 * ONE_MINUTE_IN_MILLISECONDS
const ONE_DAY_IN_MILLISECONDS = 24 * ONE_HOUR_IN_MILLISECONDS

const ConvertDateToAge = (date: Date) => {
  const currentDate = new Date()
  const ageInMilliseconds = currentDate.getTime() - date.getTime()

  if (ageInMilliseconds < ONE_SECOND_IN_MILLISECONDS) {
    return '1 second'
  } else if (ageInMilliseconds < ONE_MINUTE_IN_MILLISECONDS) {
    const ageInSeconds = Math.floor(
      ageInMilliseconds / ONE_SECOND_IN_MILLISECONDS
    )
    return `${ageInSeconds} ${ageInSeconds === 1 ? 'second' : 'seconds'}`
  } else if (ageInMilliseconds < ONE_HOUR_IN_MILLISECONDS) {
    const ageInMinutes = Math.floor(
      ageInMilliseconds / ONE_MINUTE_IN_MILLISECONDS
    )
    return `${ageInMinutes} ${ageInMinutes === 1 ? 'minute' : 'minutes'}`
  } else if (ageInMilliseconds < 24 * ONE_HOUR_IN_MILLISECONDS) {
    const ageInHours = Math.floor(ageInMilliseconds / ONE_HOUR_IN_MILLISECONDS)
    return `${ageInHours} ${ageInHours === 1 ? 'hour' : 'hours'}`
  } else {
    const ageInDays = Math.floor(ageInMilliseconds / ONE_DAY_IN_MILLISECONDS)
    return `${ageInDays} ${ageInDays === 1 ? 'day' : 'days'}`
  }
}

export default ConvertDateToAge
