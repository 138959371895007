import { VoidFunctionComponent } from 'react'
import { Alert, Modal, Toaster, Typography } from '@matillion/component-library'

import useCreateCredentials from '../effects/mutations/useCreateCredentials'

import classes from './CreateCredentials.module.scss'
import CreateCredentialsFormWrapper from './CreateCredentialsForm/CreateCredentialsFormWrapper'
import { CreateCredentialsFormValues } from './CreateCredentialsForm/CreateCredentialsForm'
import { Credential } from '../types'
import { useUser } from '@matillion/hub-client'
import CopyToClipboard from '../CopyToClipboard/CopyToClipboard'
import getMessageFromError from '../../util/getMessageFromError'

interface CreateCredentialsProps {
  onClose: () => void
}

const CreateCredentials: VoidFunctionComponent<CreateCredentialsProps> = ({
  onClose
}) => {
  const { makeToast } = Toaster.useToaster()
  const { organisation } = useUser()
  const { createCredentials, isSuccess, data } = useCreateCredentials()

  const handleCreateCredentials = async (
    values: CreateCredentialsFormValues
  ) => {
    try {
      await createCredentials({
        orgId: organisation.id,
        name: values.credentialsName
      })
      makeToast({
        title: 'API credentials created.',
        message: 'A new set of API credentials have been created',
        type: 'success'
      })
    } catch (e) {
      const message = getMessageFromError(e)
      makeToast({
        title: "We couldn't create your API credentials right now",
        message: `${message ? message + '. ' : ''}Please try again later.`,
        type: 'error'
      })
    }
  }

  const handleCopySecret = () => {
    makeToast({
      title: 'Secret copied',
      message: 'The generated secret has been attached to your clipboard',
      type: 'success'
    })
    onClose()
  }

  const handleCopyError = (errorMessage: string) => {
    makeToast({
      title: 'The secret was not copied',
      message: errorMessage,
      type: 'error'
    })
  }

  const displayCredentialsResults = (credentials: Credential) => {
    return (
      <div className={classes.DisplayResults}>
        <Typography className={classes.DisplayResults__Title} format={'tl'}>
          Copy your Secret
        </Typography>
        <Alert
          message={
            <Typography>
              {
                "You won't be able to see your Secret again once you leave this page."
              }
            </Typography>
          }
          theme="light"
          type="warning"
        />
        <div className={classes.DisplayResults__Secret}>
          <Typography format={'bcm'} weight={'bold'}>
            Secret:
          </Typography>
          <Typography format={'bcm'}>
            {credentials.clientSecret ?? 'Error: Secret not found'}
          </Typography>
        </div>
        <CopyToClipboard
          as={'button'}
          value={credentials.clientSecret ?? 'Error: Secret not found'}
          onSuccess={handleCopySecret}
          onError={handleCopyError}
        />
      </div>
    )
  }

  return (
    <Modal
      ariaLabelledBy="Members/Invite/Title"
      data-testid="Members/Invite"
      className={classes['Modal--medium']}
      size="default"
      onCancel={onClose}
    >
      {isSuccess && data ? (
        displayCredentialsResults(data)
      ) : (
        <CreateCredentialsFormWrapper onSubmit={handleCreateCredentials} />
      )}
    </Modal>
  )
}

export default CreateCredentials
