import { createElement, ElementType, FunctionComponent } from 'react'
import { useRouteMatch, LinkProps, Link } from 'react-router-dom'
import { MenuItem } from '@matillion/component-library'

interface MenuNavLinkProps extends LinkProps {
  to: string
  as?: ElementType
  exact?: boolean
  strict?: boolean
  [key: string]: unknown
}

const MenuNavLink: FunctionComponent<MenuNavLinkProps> = ({
  as,
  to,
  exact = false,
  strict = false,
  children,
  ...otherProps
}) => {
  const match = useRouteMatch({ path: to, exact, strict })
  const isActive = match !== null

  return createElement(
    as ?? MenuItem,
    {
      ...otherProps,
      as: Link,
      to,
      active: isActive,
      'data-testid': `MenuNavLink${isActive ? '/Active' : ''}`
    },
    children
  )
}

export default MenuNavLink
