export interface MemberRolesPayload {
  hub: boolean
  permissions: {
    mdl: boolean
    agentmanager?: boolean
    'platform-sje'?: boolean
    'saas-etl'?: boolean
    billing: boolean
  }
}

const getAppRoles = (permissions: Record<string, boolean>): string[] =>
  Object.keys(permissions).reduce((roles, app) => {
    const permission = permissions[app]

    if (!permission) {
      return roles
    }

    return [...roles, app === 'billing' ? 'billing:admin' : `${app}:user`]
  }, new Array<string>())

export const getRoles = (payload: MemberRolesPayload) => [
  'hub:user',
  ...(payload.hub ? ['hub:admin'] : []),
  ...getAppRoles(payload.permissions)
]

export default getRoles
