import { FunctionComponent } from 'react'
import {
  Grid,
  Section,
  SidebarLayout,
  SidebarMenu,
  SidebarMenuItem,
  SidebarMenuItemGroup
} from '@matillion/component-library'
import { Link, Redirect, Route, Switch, useLocation } from 'react-router-dom'
import ManageAPICredentials from './ManageAPICredentials'
import ManageAccountDetails from './ManageAccountDetails'
import { RoleGuard, useFlags } from '@matillion/hub-client'

const Manage: FunctionComponent = () => {
  const { enableManageApiCredentials, enableManageAccountDetails } = useFlags()
  const location = useLocation()

  const managePath = '/manage'
  const apiCredentialsPath = managePath + '/api-credentials'
  const accountDetailsPath = managePath + '/account-details'

  return (
    <RoleGuard role={'hub:admin'}>
      <SidebarLayout
        sidebar={
          <SidebarMenu>
            <SidebarMenuItemGroup>
              {enableManageAccountDetails && (
                <SidebarMenuItem
                  active={location.pathname === accountDetailsPath}
                  as={Link}
                  to={accountDetailsPath}
                >
                  Account details
                </SidebarMenuItem>
              )}
              {enableManageApiCredentials && (
                <SidebarMenuItem
                  active={location.pathname === apiCredentialsPath}
                  as={Link}
                  to={apiCredentialsPath}
                >
                  API credentials
                </SidebarMenuItem>
              )}
            </SidebarMenuItemGroup>
          </SidebarMenu>
        }
      >
        <Grid>
          <Section>
            <Switch>
              {(enableManageAccountDetails || enableManageApiCredentials) && (
                <Route exact path={managePath}>
                  <Redirect
                    to={
                      enableManageAccountDetails
                        ? accountDetailsPath
                        : apiCredentialsPath
                    }
                  />
                </Route>
              )}
              {enableManageAccountDetails && (
                <Route path={accountDetailsPath}>
                  <ManageAccountDetails />
                </Route>
              )}
              {enableManageApiCredentials && (
                <Route path={apiCredentialsPath}>
                  <ManageAPICredentials />
                </Route>
              )}
            </Switch>
          </Section>
        </Grid>
      </SidebarLayout>
    </RoleGuard>
  )
}

export default Manage
