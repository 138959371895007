import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'

import config from 'config'

const hubApi = axios.create({
  baseURL: config.hubApi,
  headers: { 'Content-Type': 'application/json' }
})

interface CurrentOrganisationResponse {
  id: string
  subdomain: string
  name: string
  userRoles: string[]
}

/**
  You won't need to call this your application, as the Hub
  Client's AuthProvider will load the current user information
  from this endpoint for you on load. It's added here as an example
  of how we favour making calls in our applications.

  This is used in `hub.pact.spec.ts` to do contract testing against the
  Hub, so we would recommend leaving it in your codebase! Removing this
  will mean that any changes to this endpoint on the Hub could
  potentially break your application in the future.
*/
export const getCurrentOrganisation = async (
  token: string,
  requestConfig?: AxiosRequestConfig
) =>
  hubApi.get<CurrentOrganisationResponse>('/hub/v1/organisations/current', {
    ...requestConfig,
    headers: { authorization: `Bearer ${token}` }
  })

type OrganisationMembersResponse = Array<{
  id: string
  email: string
  firstName: string
  lastName: string
  icon: string
  roles: string[]
}>

export const getOrganisationMembers = async () => {
  const { data: members } = await hubApi.get<OrganisationMembersResponse>(
    '/members/v1'
  )

  return members.map((member) => ({
    ...member,
    roles: new Set(member.roles)
  }))
}

interface InviteUserRequest {
  organisation_id: string
  organisation_name: string
  created_by: string
  recipient: string
  roles: string[]
}

export const inviteUser = async (data: InviteUserRequest) =>
  hubApi.post('/invitations/v1', data)

interface EditUserRequest {
  member_id: string
  organisation_id: string
  roles: string[]
}

export const editUser = async ({ member_id: id, ...data }: EditUserRequest) =>
  hubApi.patch(`/members/v1/${id}`, data)

interface RemoveUserRequest {
  member_id: string
}

export const removeUser = async ({ member_id: id }: RemoveUserRequest) =>
  hubApi.delete(`/members/v1/${id}`)

export interface AccountUpdateRequest {
  subdomain?: string
  name?: string
}

export interface AccountUpdateResponse {
  id: string
}

export const updateAccount = async (
  orgId: string,
  request: AccountUpdateRequest
): Promise<AxiosResponse<AccountUpdateResponse>> => {
  return hubApi.patch<AccountUpdateResponse>(
    `/hub/v1/organisations/${orgId}`,
    request
  )
}
export default hubApi
