import { VoidFunctionComponent } from 'react'

interface IconProps {
  className?: string
}

const Connection: VoidFunctionComponent<IconProps> = ({
  className,
  ...otherProps
}) => (
  <svg
    width="134"
    height="54"
    viewBox="0 0 134 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M53.6665 53.6666V53.6667H50.3332C36.7344 53.6667 25.5131 43.4878 23.8728 30.3334H0.333008V23.6667H23.6665H23.8728C25.5131 10.5124 36.7345 0.333395 50.3332 0.333397L53.6664 0.333397V0.333313H60.3331V53.6666H53.6665ZM53.6664 7.00006L50.3332 7.00006C39.2875 7.00006 30.3332 15.9544 30.3332 27.0001C30.3332 38.0458 39.2875 47.0001 50.3332 47.0001H53.6664V7.00006ZM73.6664 0.333313L73.6664 13.6667H66.9998V20.3334H73.6664V33.6667H66.9998V40.3334H73.6664L73.6664 53.6666H80.3331H83.6664C97.2651 53.6666 108.486 43.4877 110.127 30.3334H133.666V23.6667H110.127C108.487 10.5123 97.2652 0.333313 83.6664 0.333313H80.3331H73.6664ZM83.6664 47H80.3331V6.99998H83.6664C94.7121 6.99998 103.666 15.9543 103.666 27C103.666 38.0457 94.7121 47 83.6664 47Z"
      fill="#222222"
    />
  </svg>
)

export default Connection
