import axios, { AxiosError } from 'axios'

const useCredentialsQueryRetry = (
  failureCount: number,
  error: Error | AxiosError
): boolean => {
  // No retry on 404 - this probably means the user just has no creds yet
  if (axios.isAxiosError(error) && error?.response?.status === 404) {
    return false
  }
  // Otherwise retry up to 3 times
  return failureCount < 3
}

export default useCredentialsQueryRetry
