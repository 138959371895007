import { FunctionComponent, useEffect, useState } from 'react'
import NoCredentialsDialog from './NoCredentialsDialog'
import useCredentialsQuery from './effects/useCredentialsQuery'
import { useUser } from '@matillion/hub-client'
import { LoadingSpinner, Toaster } from '@matillion/component-library'
import ActiveCredentials from './ActiveCredentials/ActiveCredentials'
import axios from 'axios'
import classes from './ManageAPICredentials.module.scss'
import CreateCredentials from './CreateCredentials'
import getMessageFromError from '../util/getMessageFromError'

const ManageAPICredentials: FunctionComponent = () => {
  const { organisation } = useUser()
  const { isLoading, error, data } = useCredentialsQuery(organisation.id)
  const { makeToast } = Toaster.useToaster()
  const [showCreateDialog, setShowCreateDialog] = useState(false)

  useEffect(() => {
    if (error) {
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        // No credentials exist yet - skip error message
        return
      }
      const message = getMessageFromError(error)
      makeToast({
        title: "We couldn't load any existing API credentials right now",
        message: `${message ? message + '. ' : ''}Please try again later.`,
        type: 'error'
      })
    }
    // Ignored temporarily to allow work in RELENG-73 to proceed. HUB-2396 created to actually deal with this.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  const getContent = () => {
    if (isLoading) {
      return <LoadingSpinner />
    } else {
      return data ? (
        <ActiveCredentials credentials={data} />
      ) : (
        <NoCredentialsDialog
          onCreateCredential={() => setShowCreateDialog(true)}
        />
      )
    }
  }

  return (
    <div className={classes.ManageAPICredentials__Container}>
      {showCreateDialog && (
        <CreateCredentials onClose={() => setShowCreateDialog(false)} />
      )}
      {getContent()}
    </div>
  )
}

export default ManageAPICredentials
