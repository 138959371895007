import { useQuery } from 'react-query'
import { getProjectsList } from 'api/designerPlatformApi'

import { useMemo } from 'react'

import { useAuth, useUser } from '@matillion/hub-client'
import axios, { type AxiosInstance } from 'axios'

import config from 'config'

const LIST_PROJECTS_QUERY_KEY = 'api-list-projects'

const useListProjects = (accountId: string) => {
  const { organisation } = useUser()
  const { getToken } = useAuth()

  const client: AxiosInstance = useMemo(createInstance, [
    getToken,
    organisation.id,
    organisation.region
  ])

  function createInstance() {
    const instance = axios.create()

    instance.defaults.baseURL = `${config.designerSpringApiUrl.replace(
      '{{region}}',
      organisation.region ?? 'eu1'
    )}/v1`

    instance.interceptors.request.use(async (interceptedConfig) => {
      const authHeader = `Bearer ${await getToken({
        audience: config.designerSpringApiAudience
      })}`

      interceptedConfig.headers.set({
        'account-id': organisation.id,
        Authorization: authHeader
      })

      return interceptedConfig
    })

    return instance
  }

  const { isLoading, error, data } = useQuery(
    [LIST_PROJECTS_QUERY_KEY, accountId],
    async () => getProjectsList(client)
  )

  return {
    isLoading,
    error,
    data
  }
}

export default useListProjects
