import { PlatformSubMenuItem } from '@matillion/component-library'
interface HubPlatformSubMenuItemProps {
  title: string
  description: string
  serviceKey: string
  url: string
  active: boolean
}

const HubPlatformSubMenuItem = ({
  title,
  description,
  url,
  active
}: HubPlatformSubMenuItemProps) => (
  <PlatformSubMenuItem
    active={active}
    href={url}
    title={title}
    description={description}
  />
)

export default HubPlatformSubMenuItem
