import axios, { AxiosRequestConfig } from 'axios'
import config from 'config'
import { Credential } from '../modules/Manage/ManageAPICredentials'

const baseConfig = {
  baseURL: config.userIdentityServiceApi,
  headers: { 'Content-Type': 'application/json' }
}

const userIdentityApi = axios.create(baseConfig)

interface CreateCredentialsRequest {
  name: string
  orgId: string
}

export const createCredentials = async (
  { orgId, name }: CreateCredentialsRequest,
  requestConfig?: AxiosRequestConfig
): Promise<Credential> => {
  const activeConfig = requestConfig ?? baseConfig
  activeConfig.headers = {
    ...activeConfig.headers,
    'account-id': orgId
  }

  const body = {
    name
  }

  const { data } = await userIdentityApi.post<Credential>(
    '/client-credentials',
    body,
    activeConfig
  )
  return data
}

export const getCredentials = async (
  orgId: string,
  requestConfig?: AxiosRequestConfig
): Promise<Credential> => {
  const activeConfig = requestConfig ?? baseConfig
  activeConfig.headers = {
    ...activeConfig.headers,
    'account-id': orgId
  }

  const { data } = await userIdentityApi.get<Credential>(
    '/client-credentials',
    activeConfig
  )

  return data
}

export const deleteCredential = async (
  orgId: string,
  requestConfig?: AxiosRequestConfig
) => {
  const activeConfig = requestConfig ?? baseConfig
  activeConfig.headers = {
    ...activeConfig.headers,
    'account-id': orgId
  }

  return userIdentityApi.delete('/client-credentials', activeConfig)
}

export default userIdentityApi
