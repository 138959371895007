import { ReactNode, VoidFunctionComponent } from 'react'
import { Card, Toggle, Typography } from '@matillion/component-library'
import { FieldProps } from 'formik'
import classnames from 'classnames'

import classes from './AdminPermissionField.module.scss'

interface AdminPermissionFieldProps extends FieldProps {
  title: ReactNode
  description: ReactNode
  icon: ReactNode
  className?: string
}

const AdminPermissionField: VoidFunctionComponent<
  AdminPermissionFieldProps
> = ({ field, title, description, className }) => (
  <Card
    as="label"
    className={classnames(classes.AdminPermissionField, className)}
  >
    <div className={classes.AdminPermissionField__Text}>
      <Typography
        weight="bold"
        format="bcl"
        className={classes.AdminPermissionField__Title}
      >
        {title}
      </Typography>

      <Typography format="bcm" as="div">
        {description}
      </Typography>
    </div>

    <div>
      <Toggle {...field} icons />
    </div>
  </Card>
)

export default AdminPermissionField
