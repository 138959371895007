import React from 'react'
const Designer = () => {
  return (
    <svg
      width="58"
      height="58"
      viewBox="0 0 58 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.8332 22.6667C50.0799 22.6667 54.3332 18.4134 54.3332 13.1667C54.3332 7.91996 50.0799 3.66667 44.8332 3.66667C39.5865 3.66667 35.3332 7.91996 35.3332 13.1667C35.3332 18.4134 39.5865 22.6667 44.8332 22.6667ZM44.8332 25.8333C51.8288 25.8333 57.4998 20.1623 57.4998 13.1667C57.4998 6.17106 51.8288 0.5 44.8332 0.5C37.8376 0.5 32.1665 6.17106 32.1665 13.1667C32.1665 20.1623 37.8376 25.8333 44.8332 25.8333Z"
        fill="#222222"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.1776 13.1668L19.9639 7.95304L22.203 5.71387L29.656 13.1668L22.203 20.6197L19.9639 18.3805L25.1776 13.1668Z"
        fill="#222222"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.4999 14.7502C16.8766 14.7502 14.7499 16.8768 14.7499 19.5002V33.7502H11.5833V19.5002C11.5833 15.1279 15.1277 11.5835 19.4999 11.5835H27.4166V14.7502H19.4999Z"
        fill="#222222"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.6667 35.3332H3.66667V54.3332H22.6667V35.3332ZM3.66667 32.1665C1.91777 32.1665 0.5 33.5843 0.5 35.3332V54.3332C0.5 56.0821 1.91777 57.4998 3.66667 57.4998H22.6667C24.4156 57.4998 25.8333 56.0821 25.8333 54.3332V35.3332C25.8333 33.5843 24.4156 32.1665 22.6667 32.1665H3.66667Z"
        fill="#222222"
      />
    </svg>
  )
}
export default Designer
