import React from 'react'
const Instances = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M41 24H30C29.2044 24 28.4413 24.3161 27.8787 24.8787C27.3161 25.4413 27 26.2044 27 27V41C27 41.7956 27.3161 42.5587 27.8787 43.1213C28.4413 43.6839 29.2044 44 30 44H41C41.7956 44 42.5587 43.6839 43.1213 43.1213C43.6839 42.5587 44 41.7956 44 41V27C44 26.2044 43.6839 25.4413 43.1213 24.8787C42.5587 24.3161 41.7956 24 41 24ZM42 30H40V32H42V36H40V38H42V41C42 41.2652 41.8946 41.5196 41.7071 41.7071C41.5196 41.8946 41.2652 42 41 42H30C29.7348 42 29.4804 41.8946 29.2929 41.7071C29.1054 41.5196 29 41.2652 29 41V38H38V36H29V32H38V30H29V27C29 26.7348 29.1054 26.4804 29.2929 26.2929C29.4804 26.1054 29.7348 26 30 26H41C41.2652 26 41.5196 26.1054 41.7071 26.2929C41.8946 26.4804 42 26.7348 42 27V30Z"
        fill="#222222"
      />
      <path
        d="M11.2799 17.519L12.1199 17.279V16.419C12.1103 15.6795 12.2889 14.9497 12.639 14.2982C12.9891 13.6468 13.4992 13.0951 14.1213 12.6951C14.7433 12.295 15.4569 12.0598 16.1949 12.0115C16.9329 11.9632 17.6711 12.1035 18.3399 12.419L19.1599 12.819L19.6399 12.039C20.5759 10.4254 22.0202 9.16733 23.747 8.46145C25.4737 7.75557 27.3857 7.64166 29.184 8.13752C30.9824 8.63338 32.5658 9.71107 33.6868 11.2022C34.8078 12.6933 35.4032 14.5137 35.3799 16.379V17.279L36.2999 17.379C37.4913 17.4907 38.6262 17.9394 39.5719 18.6726C40.5176 19.4058 41.2349 20.3931 41.6399 21.519C41.6399 21.639 41.6399 21.779 41.7399 21.919H43.7399C43.6654 21.5544 43.5719 21.1939 43.4599 20.839C42.9721 19.5193 42.1561 18.3456 41.0888 17.4288C40.0215 16.5119 38.7382 15.8822 37.3599 15.599C37.1944 13.4522 36.3703 11.4088 35.0002 9.74769C33.6301 8.08661 31.7808 6.88883 29.7047 6.31784C27.6286 5.74685 25.4268 5.83049 23.4 6.55733C21.3732 7.28417 19.62 8.6188 18.3799 10.379C17.7341 10.1706 17.0585 10.0693 16.3799 10.079C14.8415 10.1073 13.3655 10.6924 12.2255 11.7258C11.0854 12.7591 10.3587 14.1708 10.1799 15.699C8.23165 16.1989 6.53194 17.3909 5.39826 19.0524C4.26458 20.7139 3.77446 22.7312 4.01942 24.7276C4.26438 26.724 5.22767 28.563 6.7294 29.9012C8.23112 31.2393 10.1686 31.985 12.1799 31.999H24.9999V29.999H12.2799C10.616 29.9938 9.02171 29.3304 7.84513 28.1538C6.66854 26.9773 6.0052 25.383 5.99993 23.719C5.98757 22.2235 6.51452 20.7735 7.48423 19.6348C8.45394 18.4961 9.80147 17.745 11.2799 17.519Z"
        fill="#222222"
      />
    </svg>
  )
}
export default Instances
