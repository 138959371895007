import { FC } from 'react'
import { Button, Typography } from '@matillion/component-library'
import { Field, FormikProps, Form } from 'formik'
import classes from './AccountDetails.module.scss'
import FormikFieldWrapper from 'components/FormikFieldWrapper'

export interface AccountDetailsFormValues {
  name: string
  subdomain: string
}

const AccountDetails: FC<FormikProps<AccountDetailsFormValues>> = ({
  isValid,
  isSubmitting,
  dirty
}) => (
  <Form>
    <div className={classes.AccountDetails__FormContainer}>
      <div className={classes.AccountDetails__EditableFieldContainer}>
        <Field
          data-testid="manage-account-name-field"
          name="name"
          title="Account name"
          component={FormikFieldWrapper}
          maxLength={30}
        />
      </div>
      <div className={classes.AccountDetails__EditableFieldContainer}>
        <Field
          data-testid="manage-subdomain-field"
          name="subdomain"
          title="Subdomain"
          component={FormikFieldWrapper}
          maxLength={30}
        />
        <Typography format="dcm" className={classes.AccountDetails__Domain}>
          .matillion.com
        </Typography>
      </div>
      <div className={classes.AccountDetails__ActionsContainer}>
        <Button
          data-testid="submit-button"
          disabled={!isValid || !dirty || isSubmitting}
          waiting={isSubmitting}
          className={classes.AccountDetails__FormContainer__ConfirmButton}
          type="submit"
          text="Save changes"
          size="lg"
        />
      </div>
    </div>
  </Form>
)

export default AccountDetails
