import React from 'react'
const DataLoader = () => {
  return (
    <svg
      width="64"
      height="58"
      viewBox="0 0 64 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.1047 30.2453L19.9441 12.8244L21.9011 10.3348L44.0618 27.7558L42.1047 30.2453Z"
        fill="#222222"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.0616 30.2454L21.8949 47.662L19.9385 45.172L42.1051 27.7554L44.0616 30.2454Z"
        fill="#222222"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.3333 3.66667H3.49992L3.49992 19.5H19.3333V3.66667ZM3.49992 0.5C1.75102 0.5 0.333252 1.91776 0.333252 3.66667V19.5C0.333252 21.2489 1.75102 22.6667 3.49992 22.6667H19.3333C21.0822 22.6667 22.4999 21.2489 22.4999 19.5V3.66667C22.4999 1.91777 21.0822 0.5 19.3333 0.5H3.49992Z"
        fill="#222222"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.3333 38.5002H3.49992L3.49992 54.3335H19.3333V38.5002ZM3.49992 35.3335C1.75102 35.3335 0.333252 36.7513 0.333252 38.5002V54.3335C0.333252 56.0824 1.75102 57.5002 3.49992 57.5002H19.3333C21.0822 57.5002 22.4999 56.0824 22.4999 54.3335V38.5002C22.4999 36.7513 21.0822 35.3335 19.3333 35.3335H3.49992Z"
        fill="#222222"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M60.5 21.0832H44.6667L44.6667 36.9165H60.5V21.0832ZM44.6667 17.9165C42.9178 17.9165 41.5 19.3343 41.5 21.0832V36.9165C41.5 38.6654 42.9178 40.0832 44.6667 40.0832H60.5C62.2489 40.0832 63.6667 38.6654 63.6667 36.9165V21.0832C63.6667 19.3343 62.2489 17.9165 60.5 17.9165H44.6667Z"
        fill="#222222"
      />
    </svg>
  )
}
export default DataLoader
