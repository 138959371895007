import { VoidFunctionComponent, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import classnames from 'classnames'
import { useUser } from '@matillion/hub-client'
import { Modal, Toaster, Typography } from '@matillion/component-library'

import useInviteRequest from '../effects/mutations/useInviteRequest'
import UserForm, { UserFormValues } from '../UserForm'

import classes from '../Members.module.scss'

const InviteMember: VoidFunctionComponent = () => {
  const { makeToast } = Toaster.useToaster()
  const history = useHistory()
  const { organisation } = useUser()

  const [performInviteRequest] = useInviteRequest()

  const returnToMembersList = useCallback(() => {
    history.push('/members')
  }, [history])

  const handleInviteMember = useCallback(
    async (values: UserFormValues) => {
      try {
        await performInviteRequest(values)
        returnToMembersList()

        makeToast({
          title: 'Invitation sent.',
          message: `${values.email} has been sent a link to join ${organisation.name}`,
          type: 'success'
        })
      } catch (e) {
        makeToast({
          title: "We couldn't send your invite right now.",
          message: `Please try inviting ${values.email} again later`,
          type: 'error'
        })
      }
    },
    // Ignored temporarily to allow work in RELENG-73 to proceed. HUB-2396 created to actually deal with this.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [performInviteRequest, makeToast, returnToMembersList]
  )

  return (
    <Modal
      ariaLabelledBy="Members/Invite/Title"
      data-testid="Members/Invite"
      className={classes['Modal--medium']}
      size="default"
      onCancel={returnToMembersList}
    >
      <Typography
        id="Members/Invite/Title"
        className={classnames(classes.Modal__Title, classes.Modal__Row)}
        format="tl"
        as="h2"
      >
        Invite User
      </Typography>

      <Typography as="div" format="bcm" className={classes.Modal__Row}>
        New users will receive an invitation email, they will be asked to create
        an account and will then be able to join{' '}
        <Typography weight="bold" format="bcm" as="span">
          {organisation.name}
        </Typography>
        .
      </Typography>

      <UserForm onSubmit={handleInviteMember} onCancel={returnToMembersList} />
    </Modal>
  )
}

export default InviteMember
