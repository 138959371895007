import { useQuery } from 'react-query'
import { getOrganisationMembers } from 'api/hub'

const useMembersQuery = () => {
  const {
    isLoading,
    error,
    data: members = []
  } = useQuery('members', getOrganisationMembers)

  return {
    isLoading,
    error,
    members,
    invitations: [] // TODO: query the list invitations endpoint once implemented by HUB-424
  }
}

export default useMembersQuery
