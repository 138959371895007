import { useCallback } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useUser } from '@matillion/hub-client'

import { editUser } from 'api/hub'

import getRoles, { MemberRolesPayload } from './utils/getRoles'
import { Member } from '../../types'

interface UseEditRequestPayload extends MemberRolesPayload {
  id: string
}

const MEMBERS_QUERY_KEY = 'members'

const useEditMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(editUser, {
    onMutate: async (updatedMember) => {
      await queryClient.cancelQueries(MEMBERS_QUERY_KEY)

      const previousResult =
        queryClient.getQueryData<Member[]>(MEMBERS_QUERY_KEY) ?? []

      queryClient.setQueryData(MEMBERS_QUERY_KEY, () =>
        previousResult.map((member) => {
          if (member.id !== updatedMember.member_id) {
            return member
          }

          return {
            ...member,
            roles: new Set(updatedMember.roles)
          }
        })
      )

      return previousResult
    },
    onError: (_, __, previousResult) => {
      queryClient.setQueryData(MEMBERS_QUERY_KEY, previousResult)
    },
    onSuccess: () => {
      queryClient.invalidateQueries(MEMBERS_QUERY_KEY)
    }
  })
}

const useEditRequest = () => {
  const { mutateAsync, ...rest } = useEditMutation()
  const { organisation } = useUser()

  const performEditRequest = useCallback(
    async (payload: UseEditRequestPayload) => {
      try {
        await mutateAsync({
          member_id: payload.id,
          organisation_id: organisation.id,
          roles: getRoles(payload)
        })
      } catch (e) {
        console.error('Could not update user!', e)
        throw e
      }
    },
    // Ignored temporarily to allow work in RELENG-73 to proceed. HUB-2396 created to actually deal with this.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mutateAsync]
  )

  return [performEditRequest, rest] as const
}

export default useEditRequest
