import { FunctionComponent, useEffect, useState } from 'react'
import classnames from 'classnames'
import {
  useUser,
  MatillionApplicationLink,
  useEntitlements,
  ServiceKey,
  useServiceUrl,
  useFlags,
  useBillingData,
  useLDClient,
  Trial,
  Subscription
} from '@matillion/hub-client'
import {
  Grid,
  Section,
  Typography,
  Toaster,
  LoadingSpinner
} from '@matillion/component-library'

import config from '../../config'
import Destination, { ServiceDestination } from './components/Destination'

import QuickLink, { ServiceQuickLink } from './components/QuickLink'

import classes from './Dashboard.module.scss'
import { RecentPipelines } from 'modules/Dashboard/components/RecentPipelines'
import { useGetRecentPipelines } from 'modules/Dashboard/components/RecentPipelines/useGetRecentPipelines'
import TrialExpiredModal from './components/TrialExpiredModal/TrialExpiredModal'

const {
  externalServices: { communityUrl, academyUrl, supportUrl }
} = config

const calculateShowDesignerTile = (
  isSaasEtlUser: boolean,
  canAccessDesigner: boolean | undefined,
  enableTrialExpiredExperienceExperiment: boolean,
  hasTrialExpired: boolean
) => {
  return (
    (isSaasEtlUser && canAccessDesigner) ||
    (isSaasEtlUser && enableTrialExpiredExperienceExperiment && hasTrialExpired)
  )
}

const hasActivePaidSubscription = (
  now: Date,
  trial: Trial | undefined,
  sub: Subscription | undefined
) => {
  if (trial && sub) {
    return (
      now > new Date(trial.periodEndAt) &&
      now < new Date(sub.periodEndAt) &&
      sub.edition.id !== 'FREE'
    )
  } else {
    return false
  }
}

const Dashboard: FunctionComponent = () => {
  const { makeToast } = Toaster.useToaster()
  const { roles } = useUser()
  const { data: entitlements, isLoading, error } = useEntitlements()
  const canAccessDesigner = entitlements?.canAccessDesigner
  const isMdlUser = roles.has('mdl:admin') || roles.has('mdl:user')
  const isBillingAdmin = roles.has('billing:admin')
  const isEtlAgentsUser = roles.has('agentmanager:user')
  const isSaasEtlUser = roles.has('saas-etl:user')
  const billingUrl = useServiceUrl(ServiceKey.billingFrontend)
  const {
    rolloutEnableRecentPipelines: enableRecentPipelines,
    enableTrialExpiredExperienceExperiment
  } = useFlags()
  const { hasTrialExpired, data } = useBillingData()
  const now = new Date()
  const showDesignerTile = calculateShowDesignerTile(
    isSaasEtlUser,
    canAccessDesigner,
    enableTrialExpiredExperienceExperiment as boolean,
    hasTrialExpired() as boolean
  )
  const showModalVersion =
    enableTrialExpiredExperienceExperiment &&
    hasTrialExpired() &&
    !hasActivePaidSubscription(now, data?.trial, data?.subscription)
  const {
    isLoading: recentPipelinesIsLoading,
    error: recentPipelinesError,
    hydratedPipelines: pipelines
  } = useGetRecentPipelines()
  const hasPipelinesToShow = pipelines.length > 0

  const [showModal, setShowModal] = useState(false)

  const { track: sendLDMetric } = useLDClient()

  const handleDesignerDestinationClick = () => {
    setShowModal(true)
    sendLDMetric('hub-trial-expired-modal-designer-tile-click')
  }

  const onClose = () => {
    setShowModal(false)
  }

  useEffect(() => {
    if (error) {
      makeToast({
        title: 'Sorry something went wrong.',
        message: 'Designer is not available. Please try again later.',
        type: 'error'
      })
    }
    // Ignored temporarily to allow work in RELENG-73 to proceed. HUB-2396 created to actually deal with this.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  const observabilityContent = {
    title:
      isSaasEtlUser && canAccessDesigner
        ? 'View pipeline runs'
        : 'View Matillion ETL activity',
    description:
      isSaasEtlUser && canAccessDesigner
        ? 'View all your pipeline run activity and Matillion ETL instances in one place'
        : 'View all of your instances and your job run activity all in one place.'
  }

  if (!isLoading) {
    return (
      <>
        <Grid>
          {enableRecentPipelines &&
            hasPipelinesToShow &&
            !recentPipelinesError && (
              <RecentPipelines
                pipelines={pipelines}
                isLoading={recentPipelinesIsLoading}
              />
            )}
          <Section
            className="u-section-spacing"
            size={11}
            offset={{ small: 1, large: 2 }}
          >
            {enableRecentPipelines &&
            hasPipelinesToShow &&
            !recentPipelinesError ? (
              <Typography className={classes.Title} format="tl" as="h2">
                Services
              </Typography>
            ) : (
              <Typography className={classes.Title} format="dts" as="h1">
                What do you want to do today?
              </Typography>
            )}
            <div className={classnames(classes.Destinations)}>
              {showDesignerTile && (
                <ServiceDestination
                  serviceKey={ServiceKey.projectExplorerFrontend}
                  description="Use Matillion Designer to build end-to-end data pipelines leveraging our loading, transformation, sync and orchestration capabilities."
                  icon="designer"
                  large
                  title="Design data pipelines"
                  onClick={
                    showModalVersion
                      ? handleDesignerDestinationClick
                      : undefined
                  }
                  data-testid={'designer-destination'}
                  data-tracker-id={'designer-tile'}
                />
              )}

              {isMdlUser && (
                <ServiceDestination
                  serviceKey={ServiceKey.mdlFrontend}
                  description="Use our streamlined user interface to create and manage batch pipelines."
                  icon="data-loader"
                  large
                  title="Load data"
                />
              )}

              <ServiceDestination
                serviceKey={ServiceKey.observabilityDashboardFrontend}
                description={observabilityContent.description}
                icon="activity"
                large
                title={observabilityContent.title}
              />
            </div>
          </Section>
          <Section
            className="u-section-spacing"
            size={11}
            offset={{ small: 1, large: 2 }}
          >
            <Typography className={classes.SubTitle} format="tm" as="h2">
              Quicklinks
            </Typography>

            <div className={classnames(classes.QuickLinks)}>
              <QuickLink
                as={MatillionApplicationLink}
                href={`${billingUrl}/setup`}
                title="Add Matillion ETL instance"
                icon="instances"
              />
              {isEtlAgentsUser && (
                <ServiceQuickLink
                  serviceKey={ServiceKey.agentManagerFrontend}
                  title="Manage agents"
                  icon="agents"
                />
              )}
              <ServiceQuickLink
                serviceKey={ServiceKey.cyocFrontend}
                title="Manage custom connectors"
                icon="connectors"
              />
              {isBillingAdmin && (
                <QuickLink
                  as={MatillionApplicationLink}
                  href={`${billingUrl}/usage`}
                  title="Monitor credit usage"
                  icon="credit"
                />
              )}
              <QuickLink
                href={'https://roadmap.matillion.com/'}
                title="Share product ideas"
                icon="ideas"
              />
            </div>
          </Section>
          <Section
            className="u-section-spacing"
            size={11}
            offset={{ small: 1, large: 2 }}
          >
            <div className={classes.Divider} />
            <div className={classes.Footer}>
              <Destination
                href={communityUrl}
                description="Share knowledge and best practices with your peers and help build our roadmap."
                logo="community"
                label={{ text: '1,000+ active users', colour: 'green' }}
              />
              <Destination
                href={academyUrl}
                description="Learn our products and get Matillion certified!"
                logo="academy"
                label={{ text: '15 courses and guides', colour: 'green' }}
              />
              <Destination
                href={supportUrl}
                description="Access our Knowledge Base or contact Matillion Support for assistance with any product issues."
                logo="support"
                label={{ text: 'World-class support team', colour: 'green' }}
              />
            </div>
          </Section>
        </Grid>
        {showModal && <TrialExpiredModal onClose={onClose} />}
      </>
    )
  }
  return (
    <div className={classes.Loader}>
      <LoadingSpinner data-testid="entitlements-loader" />
    </div>
  )
}

export default Dashboard
