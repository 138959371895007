import React from 'react'
const Connectors = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.949 12.3165C29.1236 11.7926 28.8404 11.2262 28.3165 11.0516C27.7926 10.8769 27.2262 11.1601 27.0516 11.684L19.0516 35.684C18.8769 36.208 19.1601 36.7743 19.684 36.949C20.208 37.1236 20.7743 36.8404 20.949 36.3165L28.949 12.3165ZM32.1922 32.5881C31.8674 32.1414 31.9661 31.516 32.4128 31.1912L42.3008 23.9999L32.4128 16.8087C31.9661 16.4838 31.8674 15.8584 32.1922 15.4118C32.5171 14.9651 33.1425 14.8664 33.5891 15.1912L43.4771 22.3825C44.5753 23.1812 44.5753 24.8187 43.4771 25.6174L33.5891 32.8087C33.1425 33.1335 32.5171 33.0348 32.1922 32.5881ZM15.8078 15.4119C16.1326 15.8586 16.0339 16.484 15.5872 16.8088L5.69922 24.0001L15.5872 31.1913C16.0339 31.5162 16.1326 32.1416 15.8078 32.5882C15.4829 33.0349 14.8575 33.1336 14.4109 32.8088L4.52288 25.6175C3.42467 24.8188 3.42467 23.1813 4.52288 22.3826L14.4109 15.1913C14.8575 14.8665 15.4829 14.9652 15.8078 15.4119Z"
        fill="#222222"
      />
    </svg>
  )
}
export default Connectors
