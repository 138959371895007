import { FunctionComponent, createElement, ElementType, FC } from 'react'
import { Typography } from '@matillion/component-library'
import classes from './QuickLink.module.scss'
import { SelectIcon } from '../../SelectIcon'
import { ServiceKey, useServiceUrl } from '@matillion/hub-client'

export interface QuickLinkProps {
  as?: ElementType
  title: string
  className?: string
  highlight?: boolean
  [key: string]: unknown
  icon?: string
}

export interface ServiceQuickLinkProps extends QuickLinkProps {
  serviceKey: ServiceKey
}

const QuickLink: FunctionComponent<QuickLinkProps> = ({
  as,
  title,
  className,
  highlight,
  icon,
  ...otherProps
}) =>
  createElement(
    as ?? 'a',
    {
      className: classes.QuickLink,
      ...otherProps
    },
    <>
      {icon && (
        <div className={classes.QuickLink__IconContainer}>
          <div className={classes.QuickLink__Icon}>{SelectIcon(icon)}</div>
        </div>
      )}
      <Typography format="bcm" as="h3" className={classes.QuickLink__Name}>
        {title}
      </Typography>
      {highlight && (
        <Typography format="bcm" className={classes.QuickLink__Highlight}>
          Preview
        </Typography>
      )}
    </>
  )

export default QuickLink

export const ServiceQuickLink: FC<ServiceQuickLinkProps> = ({
  serviceKey,
  ...otherProps
}) => {
  const serviceUrl = useServiceUrl(serviceKey, true)
  return <QuickLink {...otherProps} href={serviceUrl}></QuickLink>
}
