import { useMemo, useContext } from 'react'

import { MembersContext } from '../context'

const useMember = (id: string) => {
  const { members } = useContext(MembersContext)

  return useMemo(
    () => members.find((member) => member.id === id),
    [members, id]
  )
}

export default useMember
