import { FunctionComponent } from 'react'
import { Route, Link } from 'react-router-dom'
import {
  Alert,
  Grid,
  Section,
  Typography,
  Button
} from '@matillion/component-library'
import classnames from 'classnames'
import { useUser } from '@matillion/hub-client'

import { MembersContext } from './context'
import useMembersQuery from './effects/useMembersQuery'
import MembersList from './MembersList'
import InviteMember from './InviteMember'
import EditMember from './EditMember'

import classes from './Members.module.scss'

const Members: FunctionComponent = () => {
  const { isLoading, error, members, invitations } = useMembersQuery()
  const { roles } = useUser()
  const isAdmin = roles.has('hub:admin')

  return (
    <MembersContext.Provider value={{ isLoading, members, invitations, error }}>
      <Grid>
        {error && (
          <Section
            className="u-section-spacing"
            size={{ small: 10, large: 8 }}
            offset={{ small: 1, large: 2 }}
          >
            <Alert
              type="warning"
              title="We couldn't load all of your account's users right now."
              message="Please try again later."
            />
          </Section>
        )}

        {isAdmin && (
          <Section
            size={{ small: 10, large: 8 }}
            offset={{ small: 1, large: 2 }}
            className={classnames('u-section-spacing', classes.MemberControls)}
          >
            <Link to="/members/invite">
              <Button alt="primary" as="span" size="lg">
                <Typography weight="bold" format="bcm">
                  Invite User
                </Typography>
              </Button>
            </Link>
          </Section>
        )}

        <Section
          className="u-section-spacing"
          size={{ small: 10, large: 8 }}
          offset={{ small: 1, large: 2 }}
        >
          <MembersList />
        </Section>
      </Grid>

      {isAdmin && (
        <Route path="/members/invite">
          <InviteMember />
        </Route>
      )}

      {!isLoading && (
        <Route path="/members/:id/edit">
          <EditMember />
        </Route>
      )}
    </MembersContext.Provider>
  )
}

export default Members
