import React from 'react'
const Activity = () => {
  return (
    <svg
      width="61"
      height="52"
      viewBox="0 0 61 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.6666 3.66667H35.1666V48H25.6666V10V3.66667ZM38.3333 19.5V48H47.8333V19.5H38.3333ZM38.3333 16.3333V0.5H22.4999V10H9.83325V48H0.333252V51.1667H9.83325H22.4999H25.6666H38.3333H60.4999V48H50.9999V16.3333H38.3333ZM22.4999 48H12.9999V13.1667H22.4999V48Z"
        fill="#222222"
      />
    </svg>
  )
}
export default Activity
