import { AxiosInstance, AxiosRequestConfig } from 'axios'

export interface Projects {
  results: Project[]
}

export interface Project {
  description: string
  id: string
  name: string
  warehouse: string
}

export const getProjectsList = async (
  client: AxiosInstance,
  requestConfig?: AxiosRequestConfig
): Promise<Projects> => {
  const { data } = await client.get<Projects>('/projects', requestConfig)

  return data
}
