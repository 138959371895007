import { FunctionComponent } from 'react'
import { Table as T } from '@matillion/component-library'

import { Invitation } from '../types'
import MemberProfile from './MemberProfile'

const { TableRow, TableCell } = T

const InvitationListItem: FunctionComponent<Invitation> = ({ email }) => (
  <TableRow>
    <TableCell width="12">
      <MemberProfile email={email} />
    </TableCell>
  </TableRow>
)

export default InvitationListItem
