import './base.scss'

import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import TagManager from 'react-gtm-module'
import { BrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import {
  AuthProvider,
  FlagProvider,
  ServiceRegistryProvider
} from '@matillion/hub-client'
import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'

import config from './config'
import App from './App'
import { overrideXHRLogLevelForStatusCode } from './datadog'

if (config.datadog) {
  const { rumService, service, apiOrigin, ...options } = config.datadog

  datadogLogs.init({
    ...options,
    service,
    forwardErrorsToLogs: true,
    sampleRate: 100,
    beforeSend: overrideXHRLogLevelForStatusCode(0, 'warn', [
      'l.clarity.ms',
      'events.launchdarkly.com',
      'doubleclick.net'
    ])
  })

  datadogRum.init({
    ...options,
    version: config.version,
    service: rumService,
    sampleRate: 100,
    allowedTracingOrigins: [apiOrigin]
  })
}

if (config.gtmId) {
  TagManager.initialize({ gtmId: config.gtmId })
}

const queryClient = new QueryClient()
const root = document.getElementById('root')

ReactDOM.render(
  <StrictMode>
    <AuthProvider environment={config.authEnvironment}>
      <FlagProvider
        flags={config.launchDarkly.defaultFlags}
        launchDarklyClientId={config.launchDarkly.clientId}
      >
        <ServiceRegistryProvider
          endpoints={{
            'project-explorer-frontend':
              config.externalServices.projectExplorerUrl,
            'mdl-frontend': config.externalServices.dataloaderUrl,
            'observability-dashboard-frontend':
              config.externalServices.observabilityDashboardUrl,
            'billing-frontend': config.externalServices.billingUrl,
            'cyoc-frontend': config.externalServices.cyocUrl,
            'agent-manager-frontend': config.externalServices.etlAgentsUrl
          }}
        >
          <BrowserRouter>
            <QueryClientProvider client={queryClient}>
              <App />
            </QueryClientProvider>
          </BrowserRouter>
        </ServiceRegistryProvider>
      </FlagProvider>
    </AuthProvider>
  </StrictMode>,
  root,
  () => {
    root?.setAttribute('data-app-environment', config.environment)
    root?.setAttribute('data-app-version', config.version)
  }
)
