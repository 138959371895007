import { FC, SVGProps } from 'react'

interface CopyIconProps extends SVGProps<SVGSVGElement> {
  color: string
  className?: string
}

const CopyIcon: FC<CopyIconProps> = ({ color, className, ...otherProps }) => (
  <svg
    width="17"
    height="20"
    viewBox="0 0 17 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...otherProps}
  >
    <path
      d="M5.05 16.275C4.69 16.275 4.375 16.14 4.105 15.87C3.835 15.6 3.7 15.285 3.7 14.925V2.32498C3.7 1.96498 3.835 1.64998 4.105 1.37998C4.375 1.10998 4.69 0.974976 5.05 0.974976H14.95C15.31 0.974976 15.625 1.10998 15.895 1.37998C16.165 1.64998 16.3 1.96498 16.3 2.32498V14.925C16.3 15.285 16.165 15.6 15.895 15.87C15.625 16.14 15.31 16.275 14.95 16.275H5.05ZM5.05 14.925H14.95V2.32498H5.05V14.925ZM2.35 18.975C1.99 18.975 1.675 18.84 1.405 18.57C1.135 18.3 1 17.985 1 17.625V4.05748H2.35V17.625H13.015V18.975H2.35Z"
      fill={color}
      stroke={color}
      strokeWidth="0.1"
    />
  </svg>
)

export default CopyIcon
