import React from 'react'
const Agents = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M42.2801 28H35.6201C35.1779 28 34.7536 28.1742 34.4391 28.485C34.1246 28.7957 33.9453 29.2179 33.9401 29.66V32H30.0001V29.64C29.9948 29.1979 29.8155 28.7757 29.501 28.465C29.1865 28.1542 28.7622 27.98 28.3201 27.98H21.7201C21.2798 27.98 20.8576 28.1549 20.5463 28.4662C20.235 28.7775 20.0601 29.1998 20.0601 29.64V36.3C20.06 36.7421 20.2343 37.1664 20.545 37.4809C20.8558 37.7954 21.278 37.9747 21.7201 37.98H28.3801C28.824 37.9748 29.2483 37.7962 29.5623 37.4822C29.8762 37.1683 30.0549 36.744 30.0601 36.3V34H34.0001V36.32C34.0053 36.764 34.1839 37.1883 34.4979 37.5022C34.8118 37.8162 35.2361 37.9948 35.6801 38H42.3401C42.7821 37.9947 43.2043 37.8154 43.5151 37.5009C43.8258 37.1864 44.0001 36.7621 44.0001 36.32V29.64C43.9975 29.4186 43.9507 29.1999 43.8624 28.9968C43.7741 28.7937 43.646 28.6103 43.4857 28.4575C43.3254 28.3047 43.1362 28.1855 42.9291 28.1069C42.7221 28.0283 42.5014 27.992 42.2801 28ZM28.0001 36H22.0001V30H28.0001V36ZM42.0001 36H36.0001V30H42.0001V36Z"
        fill="#222222"
      />
      <path
        d="M10 28.98V12H34V26H36V12H38V10H6V12H8V29C8 30.3261 8.52678 31.5979 9.46447 32.5355C10.4021 33.4732 11.6739 34 13 34H18V32H13C12.6043 32 12.2126 31.9218 11.8473 31.7697C11.482 31.6177 11.1504 31.395 10.8716 31.1143C10.5927 30.8336 10.3722 30.5005 10.2226 30.1342C10.073 29.7679 9.99736 29.3756 10 28.98Z"
        fill="#222222"
      />
      <path d="M26 15.98H18V17.98H26V15.98Z" fill="#222222" />
    </svg>
  )
}
export default Agents
