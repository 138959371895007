import { VoidFunctionComponent } from 'react'
import { Field } from '@matillion/component-library'
import { FieldProps as FormikFieldProps } from 'formik'

const FieldGroup: VoidFunctionComponent<FormikFieldProps> = ({
  field: { name, ...fieldProps },
  form: { touched, errors },
  ...otherProps
}) => (
  <Field
    {...otherProps}
    {...fieldProps}
    errorText={touched[name] && errors[name]}
    name={name}
  />
)

export default FieldGroup
