import { ServiceKey, useServiceUrl, useUser } from '@matillion/hub-client'
import config from 'config'
import useListProjects from './useListProjects'

export interface RawPipeline {
  projectId: string
  branchId: string
  pipelineId: string
  environmentId: string
  agentId: string
}

export interface HydratedPipeline {
  projectName: string
  warehouse: string
  branchName: string
  pipelineId: string
  pipelineName: string
  pipelineFolder: string
  pipelineType: string
  url: string
}

const getPipelinesFromCookie = (cookieName: string) => {
  const cookieData = document.cookie
  if (cookieData) {
    const cookieArray = cookieData.split(';')
    for (const cookie of cookieArray) {
      const [name, value] = cookie.split('=').map((c) => c.trim())
      if (name === cookieName) {
        const regex = /#(.*?)#/g
        return decodeURIComponent(value).match(regex) ?? []
      }
    }
  }
  return []
}

const convertStringToPipeline = (pipelineString: string) => {
  const regex = /(\w{3}):([^|]+)(?=\|)/g
  let match
  const attributes: { [key: string]: string } = {}

  while ((match = regex.exec(pipelineString)) !== null) {
    const [, key, value] = match
    attributes[key] = value
  }

  if (
    attributes.PID &&
    attributes.BID &&
    attributes.JID &&
    attributes.EID &&
    attributes.AID
  ) {
    const pipelineItem: RawPipeline = {
      projectId: attributes.PID,
      branchId: attributes.BID,
      pipelineId: attributes.JID,
      environmentId: attributes.EID,
      agentId: attributes.AID
    }

    return pipelineItem
  }

  return undefined
}

export const useGetRecentPipelines = (): {
  isLoading: boolean
  error: unknown
  hydratedPipelines: HydratedPipeline[]
} => {
  const { organisation } = useUser()
  const { isLoading, error, data } = useListProjects(organisation.id)
  const serviceUrl = useServiceUrl(ServiceKey.designerFrontend, true)

  const pipelinesFromCookie = getPipelinesFromCookie(
    `recentlyAccessedPipelines.${organisation.id}.${config.environment}`
  )

  if (pipelinesFromCookie.length > 0) {
    const pipelines: RawPipeline[] = pipelinesFromCookie
      .map((pipelineString) => convertStringToPipeline(pipelineString))
      .filter((pipeline): pipeline is RawPipeline => pipeline !== undefined)

    const hydratedPipelines: HydratedPipeline[] = pipelines.map((pipeline) => {
      const pipelineProject = data?.results.find(
        (project) => project.id === pipeline.projectId
      )
      const projectName = pipelineProject?.name ?? ''
      const warehouse = pipelineProject?.warehouse ?? ''

      const branchName = pipeline.branchId

      const pipelineId = pipeline.pipelineId

      const pipelineIdParts = pipeline.pipelineId.split('/')
      const pipelineName =
        pipelineIdParts[pipelineIdParts.length - 1].split('.')[0]
      const pipelineFolder = pipelineIdParts.slice(0, -1).join('/')
      const pipelineType =
        pipelineIdParts[pipelineIdParts.length - 1].split('.')[1]

      const url = `${serviceUrl}/project/${pipeline.projectId}/branch/${
        pipeline.branchId
      }/job/${encodeURIComponent(pipeline.pipelineId)}?environmentId=${
        pipeline.environmentId
      }&agentId=${pipeline.agentId}`

      return {
        projectName,
        warehouse,
        branchName,
        pipelineId,
        pipelineName,
        pipelineFolder,
        pipelineType,
        url
      }
    })

    return { isLoading, error, hydratedPipelines }
  }

  return {
    isLoading: false,
    error: 'There was an error',
    hydratedPipelines: []
  }
}
