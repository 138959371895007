import { useState, VoidFunctionComponent } from 'react'
import { Button, Modal, Typography } from '@matillion/component-library'
import classes from './ConfirmModal.module.scss'

interface ConfirmModalProps {
  config: {
    title: string
    warning: string
    confirmButtonText: string
  }
  onConfirm: () => Promise<void>
  onClose: () => void
}

const ConfirmModal: VoidFunctionComponent<ConfirmModalProps> = ({
  onClose,
  onConfirm,
  config
}) => {
  const [isLoading, setIsLoading] = useState(false)

  const handleConfirmClick = () => {
    // Don't confirm again while onConfirm is loading
    if (!isLoading) {
      setIsLoading(true)
      onConfirm().finally(() => {
        setIsLoading(false)
      })
    }
  }

  return (
    <Modal
      ariaLabelledBy={'Confirm action modal'}
      data-testid="confirm-action-modal"
      className={classes.ModalContainer}
      size="default"
      onCancel={onClose}
    >
      <Typography format={'tl'}>{config.title}</Typography>
      <Typography format={'bcm'}>{config.warning}</Typography>
      <div className={classes.ButtonGroup}>
        <Button
          data-testid="confirm-modal-cancel-btn"
          onClick={onClose}
          alt="secondary"
          size="lg"
        >
          <Typography
            className={classes.ButtonText}
            format={'bcs'}
            weight={'bold'}
          >
            Cancel
          </Typography>
        </Button>
        <Button
          data-testid="confirm-modal-confirm-btn"
          onClick={handleConfirmClick}
          waiting={isLoading}
          text={config.confirmButtonText}
          alt="danger"
          size="lg"
        />
      </div>
    </Modal>
  )
}

export default ConfirmModal
