import React from 'react'
const Credit = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 8H26V34H20V11V8ZM28 20V34H32V36H28H20H18H10H4V34H10V11H18V6H28V18H36V24H34V20H28ZM18 13.2727H12V33.7273H18V13.2727ZM40 26V27.8853C40.6529 27.9963 41.2774 28.2144 41.8339 28.5315C42.8419 29.1058 43.5555 29.9646 43.8386 30.9432L41.388 31.4189C41.2479 30.9344 40.8946 30.5094 40.3959 30.2252C39.897 29.941 39.2875 29.8175 38.6844 29.8784C38.0814 29.9393 37.527 30.1803 37.1273 30.5554C36.7327 30.9257 36.5164 31.401 36.5185 31.8922C36.5538 32.3959 36.8006 32.8743 37.2175 33.2462C37.6318 33.6157 38.1881 33.8571 38.7931 33.9304C40.424 34.0327 41.943 34.6512 43.0276 35.6555L43.0761 35.7004L43.1179 35.7496C43.683 36.4145 43.9899 37.2037 43.9985 38.0137C44.0286 39.0112 43.6119 39.9833 42.8272 40.7465C42.0995 41.4543 41.102 41.937 40 42.1192V44H38V42.1147C37.3855 42.0103 36.7956 41.8109 36.2634 41.5226C35.2434 40.9702 34.5067 40.1301 34.1933 39.1619L36.6277 38.6331C36.7828 39.1123 37.1474 39.5281 37.6522 39.8015C38.1571 40.0749 38.7669 40.1869 39.3659 40.116C39.9648 40.0452 40.5111 39.7966 40.901 39.4174C41.2909 39.0381 41.4972 38.5547 41.4806 38.0593L41.4798 38.037C41.4771 37.6598 41.3419 37.2917 41.0907 36.9766C40.4373 36.3955 39.5366 36.0405 38.5726 35.9859L38.5319 35.9836L38.4914 35.9791C37.2897 35.8466 36.1827 35.3734 35.3626 34.6418C34.5424 33.9102 34.0609 32.9663 34.0018 31.9741L34.0007 31.9555L34.0004 31.9369C33.9849 30.9312 34.4227 29.9561 35.2302 29.1984C35.9537 28.5195 36.9284 28.0579 38 27.8807V26H40Z"
        fill="#222222"
      />
    </svg>
  )
}
export default Credit
