import { useQuery } from 'react-query'
import { getCredentials } from 'api/userIdentityApi'
import useCredentialsQueryRetry from './useCredentialsQueryRetry'

const CREDENTIALS_QUERY_KEY = 'api-credentials'

const useCredentialsQuery = (orgId: string) => {
  const { isLoading, error, data } = useQuery(
    [CREDENTIALS_QUERY_KEY, orgId],
    async () => getCredentials(orgId),
    {
      retry: useCredentialsQueryRetry
    }
  )

  return {
    isLoading,
    error,
    data
  }
}

export default useCredentialsQuery
