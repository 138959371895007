import { FunctionComponent, useState } from 'react'
import { Route, Link } from 'react-router-dom'
import {
  Button,
  Badge,
  Table as T,
  Popover,
  Menu,
  MenuItem,
  MenuItemGroup
} from '@matillion/component-library'
import { useUser } from '@matillion/hub-client'

import ContextMenu from 'components/Icons/ContextMenu'

import { Member } from '../types'
import MemberProfile from './MemberProfile'

import classes from './MemberListItem.module.scss'
import RemoveMember from '../RemoveMember'

const { TableRow, TableCell } = T

const MemberListItem: FunctionComponent<Member> = ({
  id,
  email,
  roles: memberRoles,
  firstName,
  lastName,
  icon
}) => {
  const [isContextMenuOpen, setContextMenuOpen] = useState(false)

  const { user: currentUser, roles: userRoles } = useUser()
  const isUserAdmin = userRoles.has('hub:admin')

  const isMemberAdmin = memberRoles.has('hub:admin')
  const isCurrentUser = currentUser.email === email

  return (
    <>
      <TableRow>
        <TableCell width="6">
          <MemberProfile
            email={email}
            firstName={firstName}
            lastName={lastName}
            icon={icon}
          />
        </TableCell>

        <TableCell width="5">
          {isMemberAdmin ? (
            <Badge
              data-testid="MembersList/Member/Role"
              colour="green"
              className={classes.AdminRoleBadge}
            >
              User Admin
            </Badge>
          ) : (
            <Badge
              data-testid="MembersList/Member/Role"
              colour="blue"
              className={classes.UserRoleBadge}
            >
              User
            </Badge>
          )}
        </TableCell>

        <TableCell>
          {isUserAdmin && (
            <Popover
              position="bottom"
              align="end"
              isOpen={isContextMenuOpen}
              onClickOutside={() => setContextMenuOpen(false)}
              anchor={
                <Button alt="text" onClick={() => setContextMenuOpen(true)}>
                  <ContextMenu
                    data-testid="MembersList/Member/Menu"
                    className={classes.ContextMenu}
                  />
                </Button>
              }
            >
              <Menu onClick={() => setContextMenuOpen(false)}>
                <MenuItemGroup>
                  <MenuItem as={Link} to={`/members/${id}/edit`}>
                    Edit User
                  </MenuItem>
                  {!isCurrentUser && (
                    <MenuItem as={Link} to={`/members/${id}/remove`}>
                      Remove User
                    </MenuItem>
                  )}
                </MenuItemGroup>
              </Menu>
            </Popover>
          )}
        </TableCell>
      </TableRow>

      {!isCurrentUser && (
        <Route path="/members/:id/remove">
          <RemoveMember />
        </Route>
      )}
    </>
  )
}

export default MemberListItem
