import axios from 'axios'

const getMessageFromError = (error: unknown): string | undefined => {
  if (axios.isAxiosError(error)) {
    return (error.response?.data?.message as string) ?? error.message
  } else if (error instanceof Error) {
    return error.message
  } else {
    return undefined
  }
}

export default getMessageFromError
