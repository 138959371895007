import {
  Card,
  LoadingSpinner,
  Section,
  Tooltip,
  Typography
} from '@matillion/component-library'
import { type HydratedPipeline } from './useGetRecentPipelines'
import classes from './RecentPipelines.module.scss'
import { ReactComponent as OrchestrationSvg } from './assets/OrchestrationJob.svg'
import { ReactComponent as TransformationSvg } from './assets/TransformationJob.svg'
import { ReactComponent as FolderSvg } from './assets/folder.svg'
import { ReactComponent as BranchSvg } from './assets/branch.svg'
import { ReactComponent as SnowflakeSvg } from './assets/snowflake.svg'
import { ReactComponent as DatabricksSvg } from './assets/databricks.svg'
import { ReactComponent as RedshiftSvg } from './assets/redshift.svg'

export const RecentPipelines = ({
  pipelines,
  isLoading
}: {
  pipelines: HydratedPipeline[]
  isLoading: boolean
}) => {
  const getWarehouseLogo = (warehouse: string) => {
    const warehouseMap: Record<string, JSX.Element> = {
      snowflake: <SnowflakeSvg className={classes.Detail__Icon} />,
      databricks: <DatabricksSvg className={classes.Detail__Icon} />,
      redshift: <RedshiftSvg className={classes.Detail__Icon} />
    }

    return warehouseMap[warehouse]
  }

  return (
    <Section
      className="u-section-spacing"
      size={11}
      offset={{ small: 1, large: 2 }}
    >
      <Typography format="tl" as="h2" className={classes.Title}>
        Recent pipelines
      </Typography>
      {isLoading ? (
        <div className={classes.Loader}>
          <LoadingSpinner data-testid="recent-pipelines-loader" />
        </div>
      ) : (
        <Section className={classes.Cards}>
          {pipelines.map((pipeline: HydratedPipeline) => (
            <Section
              key={pipeline.pipelineId}
              size={3}
              className={classes.Cards__CardContainer}
            >
              <Tooltip
                content={pipeline.pipelineName}
                position="top"
                reposition
                className={classes.Cards__CardContainer__Tooltip}
              >
                <a href={pipeline.url} data-testid="recent-pipeline-card-link">
                  <Card
                    className={classes.Cards__CardContainer__Card}
                    data-testid="recent-pipeline-card"
                  >
                    <div className={classes.ContentTop}>
                      <Typography
                        weight={'bold'}
                        format="bcm"
                        className={classes.ContentTop__Title}
                      >
                        {pipeline.pipelineName}
                      </Typography>
                      <div className={classes.ContentTop__PipelineTypeIcon}>
                        {(pipeline.pipelineType === 'TRANSFORMATION' ||
                          pipeline.pipelineType === 'tran') && (
                          <TransformationSvg data-testid="transformation-job-icon" />
                        )}
                        {(pipeline.pipelineType === 'ORCHESTRATION' ||
                          pipeline.pipelineType === 'orch') && (
                          <OrchestrationSvg data-testid="orchestration-job-icon" />
                        )}
                      </div>
                    </div>
                    {pipeline.warehouse && pipeline.projectName && (
                      <div className={classes.Detail}>
                        {getWarehouseLogo(pipeline.warehouse)}
                        <Typography
                          format="mc"
                          className={classes.Detail__Text}
                        >
                          <span className={classes.Detail__Text__Label}>
                            Project:{' '}
                          </span>
                          {pipeline.projectName}
                        </Typography>
                      </div>
                    )}
                    <div className={classes.Detail}>
                      <BranchSvg className={classes.Detail__Icon} />
                      <Typography format="mc" className={classes.Detail__Text}>
                        <span className={classes.Detail__Text__Label}>
                          Branch:{' '}
                        </span>
                        {pipeline.branchName}
                      </Typography>
                    </div>
                    {pipeline.pipelineFolder && (
                      <div className={classes.Detail}>
                        <FolderSvg className={classes.Detail__Icon} />
                        <Typography
                          format="mc"
                          className={classes.Detail__Text}
                        >
                          <span className={classes.Detail__Text__Label}>
                            Folder:{' '}
                          </span>
                          {pipeline.pipelineFolder}
                        </Typography>
                      </div>
                    )}
                  </Card>
                </a>
              </Tooltip>
            </Section>
          ))}
        </Section>
      )}
    </Section>
  )
}
