import { useCallback } from 'react'
import { useMutation, useQueryClient } from 'react-query'

import { deleteCredential } from 'api/userIdentityApi'

const CREDENTIALS_QUERY_KEY = 'api-credentials'

const useDeleteCredentialMutation = (orgId: string) => {
  const queryClient = useQueryClient()

  return useMutation(deleteCredential, {
    onMutate: () => {
      // Save the previous data in the context object
      const previousData = queryClient.getQueryData([
        CREDENTIALS_QUERY_KEY,
        orgId
      ])
      return { previousData }
    },
    onSuccess: () => {
      // Invalidate queries, and set query data so cache is immediately updated
      queryClient.invalidateQueries([CREDENTIALS_QUERY_KEY, orgId])
      queryClient.setQueryData([CREDENTIALS_QUERY_KEY, orgId], undefined)
    },
    onError: (_, __, previousResult) => {
      // Restore previous data
      queryClient.setQueryData(
        [CREDENTIALS_QUERY_KEY, orgId],
        previousResult?.previousData
      )
    }
  })
}

const useDeleteCredential = (orgId: string) => {
  const { mutateAsync } = useDeleteCredentialMutation(orgId)

  return useCallback(async () => {
    try {
      await mutateAsync(orgId)
    } catch (e) {
      console.error('Could not delete credential!', e)
      throw e
    }
    // Ignored temporarily to allow work in RELENG-73 to proceed. HUB-2396 created to actually deal with this.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutateAsync])
}

export default useDeleteCredential
