import { useCallback, useMemo, VoidFunctionComponent } from 'react'
import { Formik } from 'formik'
import * as yup from 'yup'

import { Member } from '../types'

import UserForm, { UserFormValues } from './UserForm'

export interface UserFormWrapperProps {
  member?: Member
  onCancel: () => void
  onSubmit: (values: UserFormValues) => Promise<void>
}

const DEFAULT_VALUES = {
  id: '',
  email: '',
  hub: false,
  permissions: {
    mdl: false,
    agentmanager: false,
    'platform-sje': false,
    'saas-etl': false,
    billing: false
  }
}

const validationSchema = yup.object({
  email: yup
    .string()
    .required('Please enter an email address')
    .email('Please enter an email address'),
  hub: yup.boolean(),
  permissions: yup.object({
    mdl: yup.boolean(),
    agentmanager: yup.boolean(),
    'platform-sje': yup.boolean(),
    'saas-etl': yup.boolean(),
    billing: yup.boolean()
  })
})

const getMemberAppPermission = (member: Member, application: string) => {
  return (
    member.roles.has(`${application}:admin`) ||
    member.roles.has(`${application}:user`)
  )
}

const getMemberPermissions = (member: Member) => ({
  hub: member.roles.has('hub:admin'),
  permissions: {
    mdl: getMemberAppPermission(member, 'mdl'),
    agentmanager: getMemberAppPermission(member, 'agentmanager'),
    'platform-sje': getMemberAppPermission(member, 'platform-sje'),
    'saas-etl': getMemberAppPermission(member, 'saas-etl'),
    billing: getMemberAppPermission(member, 'billing')
  }
})

const UserFormWrapper: VoidFunctionComponent<UserFormWrapperProps> = ({
  member,
  onSubmit,
  onCancel
}) => {
  const submitHandler = useCallback(
    async (values: UserFormValues) => onSubmit(values),
    [onSubmit]
  )

  const initialValues = useMemo(
    () => ({
      ...DEFAULT_VALUES,
      id: member?.id ?? '',
      email: member?.email ?? '',
      ...(member && getMemberPermissions(member))
    }),
    [member]
  )

  return (
    <Formik<UserFormValues>
      initialValues={initialValues}
      validateOnMount={true}
      validationSchema={validationSchema}
      onSubmit={submitHandler}
    >
      {(formikProps) => (
        <UserForm member={member} onCancel={onCancel} {...formikProps} />
      )}
    </Formik>
  )
}

export default UserFormWrapper
