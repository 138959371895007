import { VoidFunctionComponent } from 'react'

interface IconProps {
  className?: string
}

const OrgAdmin: VoidFunctionComponent<IconProps> = ({
  className,
  ...otherProps
}) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...otherProps}
  >
    <path
      d="M12 13.461c3.034 0 4.564-1.582 4.564-5.23C16.564 4.582 15.047 3 12 3S7.423 4.556 7.423 8.23c0 3.675 1.556 5.231 4.577 5.231Z"
      stroke="#222"
      strokeMiterlimit="10"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 24.669c0-2.437.633-4.462 1.915-5.874 1.275-1.404 3.254-2.27 6.098-2.27 2.246 0 3.95.553 5.193 1.477.258-.227.53-.439.816-.632-1.463-1.18-3.463-1.845-6.009-1.845-3.054 0-5.33.938-6.838 2.598C3.675 19.774 3 22.07 3 24.669v1.307h1V24.67Z"
      fill="#222"
    />
    <path
      d="M23.5 25a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.2 25a.825.825 0 0 0 .165.91l.03.03a1.001 1.001 0 1 1-1.415 1.415l-.03-.03a.825.825 0 0 0-.91-.165.824.824 0 0 0-.5.755V28a1 1 0 0 1-2 0v-.045A.825.825 0 0 0 22 27.2a.825.825 0 0 0-.91.165l-.03.03a1.001 1.001 0 1 1-1.415-1.415l.03-.03a.825.825 0 0 0 .165-.91.824.824 0 0 0-.755-.5H19a1 1 0 0 1 0-2h.045A.825.825 0 0 0 19.8 22a.825.825 0 0 0-.165-.91l-.03-.03a1.001 1.001 0 1 1 1.415-1.415l.03.03a.825.825 0 0 0 .91.165H22a.824.824 0 0 0 .5-.755V19a1 1 0 0 1 2 0v.045a.824.824 0 0 0 .5.755.825.825 0 0 0 .91-.165l.03-.03a1.001 1.001 0 1 1 1.415 1.415l-.03.03a.825.825 0 0 0-.165.91V22a.824.824 0 0 0 .755.5H28a1 1 0 0 1 0 2h-.045a.824.824 0 0 0-.755.5Z"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default OrgAdmin
