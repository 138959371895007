import { useCallback } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useUser } from '@matillion/hub-client'

import { inviteUser } from 'api/hub'

import getRoles, { MemberRolesPayload } from './utils/getRoles'
import { Invitation } from '../../types'

interface UseInviteRequestPayload extends MemberRolesPayload {
  email: string
}

const INVITE_QUERY_KEY = 'invites'

const useInviteMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(inviteUser, {
    onMutate: async (newInvite) => {
      await queryClient.cancelQueries(INVITE_QUERY_KEY)

      const previousResult =
        queryClient.getQueryData<Invitation[]>(INVITE_QUERY_KEY) ?? []

      queryClient.setQueryData(INVITE_QUERY_KEY, () => [
        ...previousResult,
        { email: newInvite.recipient }
      ])

      return previousResult
    },
    onError: (_, __, previousResult) => {
      queryClient.setQueryData(INVITE_QUERY_KEY, previousResult)
    },
    onSuccess: () => {
      queryClient.invalidateQueries(INVITE_QUERY_KEY)
    }
  })
}

const useInviteRequest = () => {
  const { mutateAsync, ...rest } = useInviteMutation()
  const { user, organisation } = useUser()

  const performInviteRequest = useCallback(
    async (payload: UseInviteRequestPayload) => {
      try {
        await mutateAsync({
          created_by: user.email,
          organisation_id: organisation.id,
          organisation_name: organisation.name,
          recipient: payload.email,
          roles: getRoles(payload)
        })
      } catch (e) {
        console.error('Could not send user invite!', e)
        throw e
      }
    },
    // Ignored temporarily to allow work in RELENG-73 to proceed. HUB-2396 created to actually deal with this.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mutateAsync]
  )

  return [performInviteRequest, rest] as const
}

export default useInviteRequest
