import { useCallback, useState } from 'react'
import classnames from 'classnames'
import axios from 'axios'

import {
  Typography,
  Toast,
  Toaster,
  Section,
  Grid
} from '@matillion/component-library'
import { useUser } from '@matillion/hub-client'
import { AccountUpdateRequest } from 'api/hub'

import { useUpdateAccount } from '../effects/mutations/useUpdateAccount'
import { AccountDetailsFormValues } from './AccountDetailsForm'
import AccountDetailsFormWrapper from './AccountDetailsFormWrapper'
import { useQueryClient } from 'react-query'

import classes from './AccountDetails.module.scss'

const ManageAccountDetails = () => {
  const { organisation } = useUser()
  const { makeToast, clearToasts } = Toaster.useToaster()
  const performUpdateAccount = useUpdateAccount(organisation.id)
  const queryClient = useQueryClient()

  const [displayConfirmationToast, setDisplayConfirmationToast] =
    useState(false)

  const domain = window.location.host
  const currentSubdomain = organisation.subdomain

  const forceRefreshAndUpdateSubdomain: (
    values: AccountDetailsFormValues
  ) => Promise<void> = useCallback(
    async (values) => {
      const updatedUrl = domain.replace(currentSubdomain, values.subdomain)

      const performUpdateUrl = () =>
        window.location.assign(`https://${updatedUrl}/manage/account-details`)

      setTimeout(performUpdateUrl, 2000)
    },
    // Ignored temporarily to allow work in RELENG-73 to proceed. HUB-2396 created to actually deal with this.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentSubdomain]
  )

  const onSubmit: (values: AccountDetailsFormValues) => Promise<void> =
    useCallback(
      async (values) => {
        try {
          const request: AccountUpdateRequest = {
            name: values.name,
            subdomain: values.subdomain
          }
          clearToasts()
          await performUpdateAccount.mutateAsync(request)
          setDisplayConfirmationToast(true)
          queryClient.invalidateQueries(['accountDetails'])
          await forceRefreshAndUpdateSubdomain(values)
        } catch (e) {
          const non409Error = () => {
            console.error(e)
            return "We couldn't update your account right now. Please try again later."
          }
          makeToast({
            type: 'error',
            title: 'Something went wrong',
            message:
              axios.isAxiosError(e) && e.response?.status === 409
                ? "We couldn't update your account as that subdomain is already in use. Please choose a different subdomain."
                : non409Error()
          })
        }
      },
      // Ignored temporarily to allow work in RELENG-73 to proceed. HUB-2396 created to actually deal with this.
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [clearToasts, makeToast]
    )

  return (
    <Grid className={classes.AccountDetails__Container}>
      <div
        className={classnames(classes.AccountDetails__ConfirmationToast, {
          [classes['AccountDetails__ConfirmationToast--show']]:
            displayConfirmationToast,
          [classes['AccountDetails__ConfirmationToast--hide']]:
            !displayConfirmationToast
        })}
      >
        <Toast
          data-testid="Toast--success"
          animateFrom="right"
          dismiss={() => null}
          message={'This page will refresh shortly to reflect your changes.'}
          theme="dark"
          title="Your changes have been made."
          type="success"
        />
      </div>
      <Section
        className={classes.AccountDetails__SectionSpacing}
        size={{ small: 8, medium: 6, large: 4 }}
        offset={{ small: 0, medium: 3, large: 4 }}
      >
        <Typography format="dts" as="h2">
          Account details
        </Typography>
        <Typography className={classes.AccountDetails__Subtitle}>
          View your account name & subdomain. You can edit your details here.
        </Typography>
      </Section>

      <Section
        className={classes.AccountDetails__SectionSpacing}
        offset={{ small: 0, medium: 3, large: 4 }}
      >
        <AccountDetailsFormWrapper onSubmit={onSubmit} />
      </Section>
    </Grid>
  )
}

export default ManageAccountDetails
