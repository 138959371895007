import { useEffect, useState } from 'react'

import {
  HeaderMenuItemGroup,
  PlatformMenu,
  PlatformSubMenu
} from '@matillion/component-library'
import { useServiceUrl, ServiceKey } from '../../effects/useServiceUrl'

import HubPlatformMenuServiceItem from './HubPlatformMenuServiceItem'
import HubPlatformSubMenuItem from './HubPlatformSubMenuItem'

import usePlatformMenuStructure, {
  PlatformMenuService
} from './effects/usePlatformMenuStructure'
import useEntitlements from '../../effects/useEntitlements'
import useUser from '../../effects/useUser'

const HubPlatformMenu = () => {
  const [{ services: standardServices, conditionalServices, subMenus }] =
    usePlatformMenuStructure()

  const { data: entitlements } = useEntitlements()
  const canSeeDesignerTile = entitlements?.canSeeDesignerTile
  const { roles } = useUser()
  const isSaasEtlUser = roles.has('saas-etl:user')
  const [services, setServices] = useState<PlatformMenuService[]>([])

  const createNewServicesList = (key: string, pos: number) => {
    const conditionalService = conditionalServices.find(
      (obj) => obj.key === key
    )

    let newServicesList: PlatformMenuService[] = []

    if (conditionalService) {
      newServicesList = [
        ...standardServices.slice(0, pos),
        conditionalService,
        ...standardServices.slice(pos)
      ]
    }

    return newServicesList
  }

  useEffect(() => {
    // Add the Designer service conditionally
    if (isSaasEtlUser && canSeeDesignerTile) {
      setServices(createNewServicesList('project-explorer-frontend', 1))
    } else {
      setServices(standardServices)
    }
  }, [standardServices, conditionalServices, canSeeDesignerTile, isSaasEtlUser])

  const useAllServiceUrlsByKeys = (): Map<string, string> => {
    const urlsByKeys = new Map<ServiceKey, string>()
    Object.values(ServiceKey).forEach((key) =>
      urlsByKeys.set(key, useServiceUrl(key, true).toString())
    )

    return urlsByKeys
  }

  const urlsByKeys = useAllServiceUrlsByKeys()

  const getUrl = (serviceKey: string, url: string) => {
    if (serviceKey) {
      const retrievedUrl = urlsByKeys.get(serviceKey)
      if (!retrievedUrl) {
        throw new Error(`No url found for serviceKey ${serviceKey}`)
      }

      return retrievedUrl
    }

    return url
  }

  return (
    <PlatformMenu data-testid="hub-platform-menu" newDesignEnabled>
      <HeaderMenuItemGroup>
        {services.map((service) => (
          <HubPlatformMenuServiceItem
            key={service.title}
            serviceKey={service.key}
            name={service.title}
            description={service.description}
          />
        ))}
      </HeaderMenuItemGroup>
      <HeaderMenuItemGroup>
        {subMenus.map((subMenu) => (
          <PlatformSubMenu
            key={subMenu.title}
            title={subMenu.title}
            description={subMenu.description}
            active={Boolean(
              subMenu.options.find(
                (option) =>
                  getUrl(option.key, option.url) === window.location.origin
              )
            )}
          >
            {subMenu.options.map((option) => (
              <HubPlatformSubMenuItem
                key={option.title}
                serviceKey={option.key}
                url={getUrl(option.key, option.url)}
                description={option.description}
                title={option.title}
                active={
                  getUrl(option.key, option.url) === window.location.origin
                }
              />
            ))}
          </PlatformSubMenu>
        ))}
      </HeaderMenuItemGroup>
    </PlatformMenu>
  )
}

export default HubPlatformMenu
