import {
  Activity,
  Agents,
  Connectors,
  Credit,
  DataLoader,
  Designer,
  Ideas,
  Instances,
  Community,
  Academy,
  Support
} from './assets'

const icons = {
  activity: <Activity />,
  agents: <Agents />,
  connectors: <Connectors />,
  credit: <Credit />,
  'data-loader': <DataLoader />,
  designer: <Designer />,
  ideas: <Ideas />,
  instances: <Instances />,
  community: <Community />,
  academy: <Academy />,
  support: <Support />
}

export const SelectIcon = (icon: string) => icons[icon as keyof typeof icons]
