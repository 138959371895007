import React from 'react'
const Ideas = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40.0002 10H8.00024C7.46981 10 6.9611 10.2107 6.58603 10.5858C6.21096 10.9609 6.00024 11.4696 6.00024 12V34C6.00024 34.5304 6.21096 35.0391 6.58603 35.4142C6.9611 35.7893 7.46981 36 8.00024 36H14.0002V44L22.0002 36H40.0002C40.5307 36 41.0394 35.7893 41.4145 35.4142C41.7895 35.0391 42.0002 34.5304 42.0002 34V12C42.0002 11.4696 41.7895 10.9609 41.4145 10.5858C41.0394 10.2107 40.5307 10 40.0002 10ZM40.0002 34H21.1802L20.5802 34.58L16.0002 39.16V34H8.00024V12H40.0002V34Z"
        fill="#222222"
      />
      <path
        d="M20.0003 27.0005C20.0003 28.0614 20.4217 29.0788 21.1719 29.8289C21.922 30.5791 22.9394 31.0005 24.0003 31.0005C25.0611 31.0005 26.0786 30.5791 26.8287 29.8289C27.5789 29.0788 28.0003 28.0614 28.0003 27.0005V24.4405C28.6314 23.8862 29.1365 23.2032 29.4815 22.4373C29.8265 21.6714 30.0034 20.8405 30.0003 20.0005C30.0011 19.0808 29.7906 18.1733 29.3848 17.3479C28.9791 16.5226 28.389 15.8015 27.6603 15.2405C26.9242 14.6711 26.0658 14.2808 25.1529 14.1003C24.24 13.9198 23.2976 13.9541 22.4003 14.2005C21.365 14.4894 20.4263 15.0511 19.6823 15.8269C18.9384 16.6027 18.4165 17.5641 18.1712 18.6106C17.926 19.6571 17.9663 20.7503 18.2881 21.7758C18.6098 22.8014 19.2012 23.7217 20.0003 24.4405V27.0005ZM24.0003 29.0005C23.4699 29.0005 22.9611 28.7898 22.5861 28.4147C22.211 28.0397 22.0003 27.531 22.0003 27.0005H26.0003C26.0003 27.531 25.7896 28.0397 25.4145 28.4147C25.0394 28.7898 24.5307 29.0005 24.0003 29.0005ZM20.1603 18.8405C20.3533 18.1965 20.7055 17.6114 21.1844 17.1395C21.6633 16.6676 22.2535 16.324 22.9003 16.1405C23.2591 16.045 23.629 15.9979 24.0003 16.0005C24.8856 15.9939 25.7466 16.2903 26.4403 16.8405C26.9243 17.2132 27.3166 17.6917 27.587 18.2395C27.8575 18.7872 27.9988 19.3896 28.0003 20.0005C27.9972 20.6181 27.8511 21.2266 27.5735 21.7783C27.2959 22.33 26.8944 22.81 26.4003 23.1805L26.0003 23.4805V25.0005H22.0003V23.4805L21.6003 23.1805C20.9455 22.691 20.4564 22.0125 20.199 21.2366C19.9415 20.4606 19.928 19.6244 20.1603 18.8405Z"
        fill="#222222"
      />
      <path
        d="M23.0002 20C23.5525 20 24.0002 19.5523 24.0002 19C24.0002 18.4477 23.5525 18 23.0002 18C22.448 18 22.0002 18.4477 22.0002 19C22.0002 19.5523 22.448 20 23.0002 20Z"
        fill="#222222"
      />
    </svg>
  )
}
export default Ideas
