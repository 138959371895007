import { VoidFunctionComponent } from 'react'

interface IconProps {
  className?: string
}

const Delete: VoidFunctionComponent<IconProps> = ({
  className,
  ...otherProps
}) => (
  <svg
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...otherProps}
  >
    <path
      d="M19.5 3.5H14V2C14 1.60218 13.842 1.22064 13.5607 0.93934C13.2794 0.658035 12.8978 0.5 12.5 0.5H7.5C7.10218 0.5 6.72064 0.658035 6.43934 0.93934C6.15804 1.22064 6 1.60218 6 2V3.5H0.5V4.5H3L3.84 16.18C3.88811 16.814 4.17501 17.406 4.64276 17.8367C5.1105 18.2673 5.72423 18.5044 6.36 18.5H13.64C14.2706 18.4968 14.8769 18.2563 15.3382 17.8265C15.7996 17.3966 16.0823 16.8088 16.13 16.18L17 4.5H19.5V3.5ZM7 2C7 1.86739 7.05268 1.74021 7.14645 1.64645C7.24021 1.55268 7.36739 1.5 7.5 1.5H12.5C12.6326 1.5 12.7598 1.55268 12.8536 1.64645C12.9473 1.74021 13 1.86739 13 2V3.5H7V2ZM15.13 16.11C15.1023 16.4867 14.9335 16.8392 14.6573 17.0968C14.381 17.3545 14.0178 17.4985 13.64 17.5H6.36C5.98225 17.4985 5.61897 17.3545 5.34275 17.0968C5.06653 16.8392 4.8977 16.4867 4.87 16.11L4 4.5H16L15.13 16.11Z"
      fill="#222222"
    />
  </svg>
)

export default Delete
