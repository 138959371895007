import { useMutation, useQueryClient } from 'react-query'
import { AxiosResponse } from 'axios'

import {
  AccountUpdateRequest,
  AccountUpdateResponse,
  updateAccount
} from 'api/hub'

export const useUpdateAccount = (accountId: string) => {
  const queryClient = useQueryClient()
  return useMutation<
    AccountUpdateResponse,
    AxiosResponse,
    AccountUpdateRequest
  >(
    'updateAccount',
    async (request) => {
      const { data } = await updateAccount(accountId, request)
      return data
    },
    {
      onSuccess() {
        queryClient.invalidateQueries('accountDetails')
      }
    }
  )
}
