import { useMemo, useCallback } from 'react'
import { Formik } from 'formik'
import * as yup from 'yup'
import AccountDetails, { AccountDetailsFormValues } from './AccountDetailsForm'
import { useUser } from '@matillion/hub-client'

const NAME_VALIDATION_REGEX = /^[^<>]+$/i
const SUBDOMAIN_VALIDATION_REGEX = /(?!.*-{2})(?!^-)(?!.*-$)^[a-z0-9-]+$/

const validationSchema = yup.object({
  name: yup
    .string()
    .required('An account name is required')
    .matches(
      NAME_VALIDATION_REGEX,
      'Your account name must contain only valid characters'
    ),

  subdomain: yup
    .string()
    .required('A subdomain is required')
    .matches(
      SUBDOMAIN_VALIDATION_REGEX,
      'Your subdomain can only contain lowercase characters, numbers, and single hyphens'
    )
})

export interface AccountDetailsFormWrapperProps {
  onSubmit: (values: AccountDetailsFormValues) => Promise<void>
}

const AccountDetailsFormWrapper = ({
  onSubmit
}: AccountDetailsFormWrapperProps) => {
  const { organisation } = useUser()

  const initialValues: AccountDetailsFormValues = useMemo(
    () => ({
      name: organisation.name,
      subdomain: organisation.subdomain
    }),
    [organisation]
  )

  const onSubmitForm = useCallback(
    async (values) => onSubmit(values),
    [onSubmit]
  )

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmitForm}
    >
      {(formikProps) => <AccountDetails {...formikProps} />}
    </Formik>
  )
}
export default AccountDetailsFormWrapper
