import { LogsEvent } from '@datadog/browser-logs'

type LogLevel = LogsEvent['status']

export const overrideXHRLogLevelForStatusCode =
  (statusCode: number, level: LogLevel, hosts: string[]) =>
  (log: LogsEvent) => {
    const shouldSetLevel =
      log.http?.status_code === statusCode &&
      hosts.some((x) => log.http?.url.includes(x))

    if (shouldSetLevel) {
      log.status = level
    }
  }
